.PhoneInputInput  {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 10px;
    margin-left: 0;
    background-color: #474548;
    border: 1px solid #7c7b80;
    color: #fff;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: 100%;
    outline: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PhoneInputCountrySelect{
  padding-left: 10px;
}
.PhoneInputCountry{
  padding:  10px ;
}
/* 
input.PhoneInputInput{
  margin: 0;
} */
/* .PhoneInputInput .selected-flag {
    outline: none;
    position: relative;
    width: 38px;
    height: 100%;
    padding: 0 0 0 6px;
     border: 1px solid #7c7b80;
    border-radius: 3px 0 0 3px;
    background-color: #474548;
}

.PhoneInputInput.country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
    background-color: #474548;
    width: 300px;
    max-height: 200px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    color: white;
}

.country-list{
    background-color: #27262b;
}

.react-tel-input:hover{
    color: #474548;
}

.country-list:hover{
    color: black;
} */

tbody + thead{
    display: none;
  }

  .pagination .page-item.active .page-link .paginate_button { cursor: pointer; }

/* div.dataTables_wrapper div.dataTables_paginate ul.pagination .page-item.active .page-link:focus {
 cursor: pointer;
} */

.pagination .page-item.active .page-link:hover {
 cursor: pointer;
}
.page-link:hover {
    cursor: pointer;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: #26252a !important;
    border: none ;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  
  
  .react-calendar__tile--now {
    background: none !important;
  }
  
  .react-calendar button {
    color: #fff;
  }
  
  .react-calendar__viewContainer {
    color: #fff;
  }

  button.note-btn.btn.btn-default.btn-sm.dropdown-toggle::after{
    visibility: hidden;
  }

  div.dropdown-menu.dropdown-style.show{
    padding: 10%;
    background-color: rgb(71, 69, 72) !important;
    color: white !important;
  }

  a:link{
    color: #fff !important;
  }

 div.note-dimension-picker-mousecatcher{

  background-color: #474548 !important;

}

div.CodeMirror-lines{
  background-color: #474548 !important;
  color: #fff !important;
}

div.CodeMirror-gutter.CodeMirror-linenumbers{
  background-color: #474548 !important;
  color: #fff !important;
}
div.CodeMirror-scroll{
  background-color: #474548 !important;
}

div.dropdown-menu.dropdown-style.show{
  width: 162px;
}

.modal-header .modal-footer {
  background-color: #2F2F34 !important;

}

.modal-body{
  background-color: #26262B !important;
}
/* .sweet-alert{
  background-color: #26262B !important;
  color: #fff !important;
}
h2{
  color: #fff !important;
} */
