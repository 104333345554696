.App {
  text-align: center;
}

*{
  font-family: Open Sans, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-calendar__tile--now.react-calendar__tile--active{
  background: #006edc !important;
}

td,tr{
  padding:10px 25px 10px 0px;
}

.formContainer input{
  width: 90%;
  border-radius: 0;
  line-height: normal;
  height: 32px;
  margin-bottom: 20px;
  padding: .1875rem .5rem;
  background-color: #45454a;
  border: 1px solid #606064;
  color: hsla(0,0%,100%,.8);
  font-family: Open Sans,sans-serif;
  transition-duration: .15s;
  transition-property: border,background-color,color,box-shadow;
  transition-timing-function: ease-in;
}

.formContainer .PhoneInput{
  width: 90%;
  border-radius: 0;
  line-height: normal;
  height: 32px;
  margin-bottom: 20px;
  padding: 0;
  background-color: #45454a;
  border: 1px solid #606064;
  color: hsla(0,0%,100%,.8);
  font-family: Open Sans,sans-serif;
  transition-duration: .15s;
  transition-property: border,background-color,color,box-shadow;
  transition-timing-function: ease-in;
}

.formContainer input:focus, .formContainer input:hover, .selectFormContainer:hover, .selectFormContainer:focus{
  border-color: #17a0fb;
  outline: none;
}

.formContainer label, .col-form-label, .selectFormContainer>option{
  color:#929295 !important
}

.selectFormContainer{
  width: 90%;
  border-radius: 0;
  line-height: normal;
  height: 32px;
  margin-bottom: 20px;
  padding: 0;
  background-color: #45454a;
  border: 1px solid #606064;
  color: hsla(0,0%,100%,.8);
  font-family: Open Sans,sans-serif;
  transition-duration: .15s;
  transition-property: border,background-color,color,box-shadow;
  transition-timing-function: ease-in;
  cursor: pointer;
}

.loginCo{
  width:50%; 
  display:inline-block;
  text-align: center;
  height: 100vh;
  overflow: hidden;
}

.loginCo img{
  width: 292px;
  height: 36px;
  position: relative;
  top: 50%;
}

.loginCo form{
  position: relative;
  top: 38%;
  text-align: left;
  padding-left: 20px;
}

.loginCo input{
    width: 40%;
    border-radius: 0;
    line-height: normal;
    height: 32px;
    margin-bottom: 20px;
    padding: .1875rem .5rem;
    background-color: #45454a;
    border: 1px solid #606064;
    color: hsla(0,0%,100%,.8);
    font-family: Open Sans,sans-serif;
    transition-duration: .15s;
    transition-property: border,background-color,color,box-shadow;
    transition-timing-function: ease-in;
}

.loginCo input:focus, .loginCo input:hover{
  border-color: #17a0fb;
  outline: none;
}

.loginCo .header{
    font-weight: 700;
    line-height: 32px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 30px;
    font-size: 1.5rem;
    letter-spacing: .46px;
}

.loginCo button{
    background-color: #17a0fb;
    border-color: #17a0fb;
    color: #fff;
    padding: 0.4375rem 0.875rem;
    border-style: solid;
    border-width: 0.0625rem;
    text-transform: uppercase;
    width: 40%;
}

::placeholder{
  color: #6c757d !important;
}

.toast-error{
  background: #e63f5d !important;
  border-color: #e63f5d !important;
  color: #FFFFFFE6 !important;
}

.toast-success{
  background: #226aa4 !important;
  border-color:  #226aa4 !important;
  color: #fff !important;
}

.toast-message-container>p{
  font-size: 13px !important;
  font-weight: 300 !important;
  margin-bottom: 0px !important;
}

.toast-message-container > h6{
  font-weight: 600;
  font-size: 15px !important;
}

.Toastify__toast-body > div:last-child{
  padding: 10px !important;
}

#alertBox h5{
  font-size: 20px;
}

#alertBox .btn{
  background: #e63f5d;
  border-color: #e63f5d;
  padding: 0.4375rem 0.875rem;
  border-style: solid;
  border-width: 0.0625rem;
  text-transform: uppercase;
  border-radius: 2px;
  font-size: .75rem;
  line-height: 1rem;
  min-width: 94px;
  font-weight: 700;
  cursor: pointer;
  outline: none;
}

#alertBox button:focus{
  outline: none !important;
  border: none;
}

#alertBox .modal-header{
  background: #2f2f34;
  padding: 22px 25px;
}

#alertBox .modal-footer{
  padding: 22px 25px;
  border-color: hsla(0,0%,100%,.1);
  background-color: #2f2f34;
}

#alertBox .modal-body{
  font-size: 12px;
  color: #ffffff99;
  height: auto !important;
}

#alertBox .modal-title{
  font-weight: 100;
}

#alertBox .modal-dialog{
  max-width: 400px;
}

.modal {
  background: rgba(0,0,0,0.8) !important;
}

.buttons-secondary{
  border: 1px solid #6a6a71 !important; 
  border-radius: 3px !important; 
  background-color: rgba(80,80,88,.8) !important;
  transition: background-color .2s linear !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.buttons-secondary:disabled{
  cursor: default;
  opacity:.2!important;
}

a.active.nav-link{
  background-color: #303035 !important;
  border-bottom: 4px solid #00A0ff !important;
  border-radius: 0 !important;
}

.form-cancel-buttons{
  border: 1px solid #6a6a71 !important; 
  border-radius: 3px !important; 
  background-color: rgba(80,80,88,.8) !important;
  transition: background-color .2s linear !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}

.form-modal-header{
  background-color: #2f2f34 !important;
  height: 60px;
}

.form-modal-body{
  background-color: #26252a !important;
}

.icons-sidebar{
  position: relative;
  left: 15%;
  font-size:20px;
  color: #8699AD !important;
}

#aTag{
  text-decoration: underline !important;
  color: #17a0fb !important;
}

.table-responsive{
  overflow: auto; 
  max-height: 500px;
}

.table-responsive thead {
  position: sticky; 
  top: -2px; 
  z-index: 1;
  background: #27262b;
}

.rdt_Table{
  overflow: auto !important; 
  max-height: 500px !important;
}

.rdt_TableHead{
  position: sticky; 
  top: -2px; 
  z-index: 1;
  background: #27262b;
}

select{
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 15px;
  border-radius: 3px;
  height: 50px;
  color: #ffffff;
  padding-left: 8px !important;
  font-size: 14px;
  border-color: blue;
  position: relative;
  border: none;
  background: #474548 url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat;
  background-position: right 5px top 50%;
  background-size: 12px;
}

select:disabled{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
}
.sc-cwSeag ~ svg {
  display: none !important;
}